import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Strona Główna" />
    <h1>Big Smile</h1>
    <p>Strona w budowie</p>
  </Layout>
)

export default IndexPage
