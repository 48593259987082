import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import './layout.css'

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Layout = ({ children }) => <Wrapper>{children}</Wrapper>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
